<template lang="html" src="./cardAttachments.template.vue"></template>

<style lang="scss" src="./cardAttachments.scss"></style>

<script>
const i18nData = require('./cardAttachments.i18n');
/**
 * Used by homologation stepper for the information part
 */
export default {
  name: 'CardAttachments',
  components: {},
  props: {
    /**
     * The part object
     */
    part: {
      type: Object,
      required: true
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return { };
  },
  created() {},
  computed: {
  },
  methods: {
    openAttachments() {
      this.$emit('openAttachments');
    },
  },
};
</script>
